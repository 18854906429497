<template>
  <div>
    <Menu :menu="accessMenu" prefix="" />
    <transition name="page">
      <router-view />
    </transition>
  </div>
</template>

<script>
import { ADDEVENT_ADMIN_ROUTES } from '@/constants/routing'
import { mapGetters } from 'vuex'

import Menu from '@/components/atoms/Menu.vue'
export default {
  components: {
    Menu,
  },
  ADDEVENT_ADMIN_ROUTES,
  data() {
    return {
      menu: [
        {
          title: 'Категории',
          link: ADDEVENT_ADMIN_ROUTES.CATALOG.CATEGORIES.LIST,
        },
        {
          title: 'Продукты',
          link: ADDEVENT_ADMIN_ROUTES.CATALOG.PRODUCTS.LIST,
        },
        {
          title: 'Бренды',
          link: ADDEVENT_ADMIN_ROUTES.CATALOG.BRANDS.LIST,
        },
        {
          title: 'Ассортименты',
          link: ADDEVENT_ADMIN_ROUTES.CATALOG.ASSORTMENTS.LIST,
        },
        {
          title: 'Теги',
          link: ADDEVENT_ADMIN_ROUTES.CATALOG.TAGS.LIST,
        },
        {
          title: 'Готовые решения',
          link: ADDEVENT_ADMIN_ROUTES.CATALOG.SOLUTIONS.LIST,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      filterAccessMenuList: 'filterAccessMenuList',
    }),
    accessMenu() {
      return this.filterAccessMenuList(this.menu)
    },
  },
}
</script>
