<template>
  <div :class="$style.menu">
    <router-link
      v-for="m in menu"
      :key="m.link"
      :to="m.link"
      :class="$style.item"
    >
      <span>{{ m.title }}</span>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    menu: { type: Array, default: () => [] }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors';
.menu {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1rem;
  padding: 2rem 5rem;

  .item {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background: $bright-gold;
    border-radius: 0.375rem;
    box-shadow: 0rem 0.625rem 0.625rem rgba(0, 0, 0, 0.15);
    min-height: 80px;
    span {
      padding: 1rem;
      font-size: 1.3rem;
      font-weight: 600;
      color: $black-gray;
      transition: all 0.3s ease-in-out;
      &:hover {
        font-size: 1.5rem;
      }
    }
  }
}
</style>
